<!-- eslint-disable -->
<template>
  <div class="information">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="pushClick2">
            <img src="../../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/index' }">预警信息</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 信息详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 外面的大盒子 -->
    <div class="information-box">
      <div v-loading="loading" class="informaton-main">
        <div class="main-top">
          <div class="main-top-left"></div>
          <!-- 中间部分 -->
          <div class="main-top-center">
            <div class="main-top-center-box">
              <div>已预警</div>
              <p
                v-html="textData.title ? textData.title : ''"
                class="center-title"
              ></p>
            </div>
            <div class="caozuo-center">
              <div
                :class="[
                  'type',
                  textData.tonalState == 2
                    ? 'negative'
                    : textData.tonalState == 1
                    ? 'neutral'
                    : 'obverse',
                ]"
              >
                {{
                  textData.tonalState == 2
                    ? "负面"
                    : textData.tonalState == 0
                    ? "正面"
                    : "中性"
                }}
              </div>
              <div v-if="textData.mediaLink != null" class="type website">
                {{ textData.mediaLink }}
              </div>
              <span
                class="original original-color"
                @click="
                  routerOriginal(textData.website ? textData.website : '无')
                "
              >
                <span>
                  <img src="../../../assets/img/views/wenzhang.png" alt />
                </span>
                <span>查看原文</span>
              </span>
              <span class="original comment-weidu">
                <span>
                  <img
                    v-if="textData.isRead == 1"
                    src="../../../assets/img/views/greenmessage.png"
                    alt
                  />
                  <img
                    v-else
                    src="../../../assets/img/views/redmessage.png"
                    alt
                  />
                </span>
                <span :class="[textData.isRead == 1 ? 'yidu' : ' ']">{{
                  textData.isRead == 1 ? "已读" : "未读"
                }}</span>
              </span>
              <div class="center-timer">
                发布时间:
                <span>
                  {{ textData.releaseTime ? textData.releaseTime : "无" }}
                </span>
              </div>
            </div>
            <div class="operation-box">
              <!-- 设置标签 -->
              <el-select
                size="small"
                class="select-biaoqian"
                v-model="textData.setLabel"
                placeholder="设置标签"
                @change="changeLabel(textData)"
              >
                <el-option
                  v-for="(ite, index) in biaoqianList"
                  :key="index"
                  :label="ite.labelName"
                  :value="ite.labelId"
                ></el-option>
              </el-select>
              <div class="operation-copy my-caozuo" @click="copy(textData)">
                <span>
                  <span>
                    <img src="../../../assets/img/views/fuzhi.png" alt />
                  </span>
                </span>
                <span>复制全部</span>
              </div>
              <div @click="quickCopy(textData)" class="my-caozuo">
                <span
                  style="margin: 0; color: black; font-size: 16px"
                  class="el-icon-link"
                ></span>
                复制链接和标题
              </div>
              <div
                :class="textData.disabled ? 'disabled' : ''"
                class="operation-jietu my-caozuo"
                @click="screenShot(textData)"
              >
                <span>
                  <span>
                    <img src="../../../assets/img/views/tupian.png" alt />
                  </span>
                </span>
                <span>页面截图</span>
              </div>
              <div
                class="operation-shoucang my-caozuo"
                @click="editShouc(textData)"
              >
                <span>
                  <span>
                    <img
                      v-if="textData.isCollect == 1"
                      src="../../../assets/img/views/yellowsc.png"
                      alt
                    />
                    <img
                      v-else
                      src="../../../assets/img/views/shoucang.png"
                      alt
                    />
                  </span>
                </span>
                <span :class="[textData.isCollect == 1 ? 'yishoucang' : ' ']">{{
                  textData.isCollect == 1 ? "已收藏" : "收藏"
                }}</span>
              </div>
              <div
                v-if="$store.state.user.isreport"
                class="operation-shoucang my-caozuo"
                @click="dataReport(textData)"
              >
                <span>
                  <span>
                    <img
                      style="width: 16px; height: 16px"
                      src="../../../assets/img/views/monitoringScheme/sahngchuan.png"
                      alt
                    />
                  </span>
                </span>
                <span>数据上报</span>
              </div>
              <div
                :class="['pinglun-num', count == 0 ? 'grey' : 'blue']"
                @click="handel"
              >
                评论({{ count }}条)
              </div>
            </div>
          </div>
          <div class="main-top-right"></div>
        </div>
        <div style="padding: 24px 210px 0 210px">
          <!-- 中间青色位置 -->
          <div class="main-center">
            <div class="main-center-left">
              <div class="left-source">
                作者ID:
                <span>
                  {{ textData.accurateId ? textData.accurateId : "无" }}
                </span>
                <span class="my-caozuo" @click="myCopy(textData.accurateId, 7)">
                  <img src="../../../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="left-source">
                媒体性质:
                <span>
                  {{
                    (textData.mediaLink ? textData.mediaLink : "其它") +
                    "/" +
                    (textData.mediaAttribute
                      ? textData.mediaAttribute
                      : "其它") +
                    "/" +
                    (textData.mediaLevel ? textData.mediaLevel : "其它")
                  }}
                </span>
                <span
                  class="my-caozuo"
                  @click="
                    myCopy(
                      [
                        textData.mediaLink,
                        textData.mediaAttribute,
                        textData.mediaLevel,
                      ],
                      4
                    )
                  "
                >
                  <img src="../../../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="left-source">
                来源/作者:
                {{
                  (textData.source ? textData.source : "无") +
                  "/" +
                  (textData.author ? textData.author : "无")
                }}
                <span class="my-caozuo" @click="myCopy('', 1)">
                  <img src="../../../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="left-language">
                关键字:
                <span v-if="textData.keyword">
                  <span
                    style="margin-left: 0px"
                    v-for="(ite, indx) in textData.keywordList"
                    :class="[
                      ite !=
                      textData.keywordList[textData.keywordList.length - 1]
                        ? 'styleMargin'
                        : '',
                    ]"
                    :key="indx"
                    >{{ ite }}</span
                  >
                </span>
                <span v-else>无</span>
                <el-tooltip
                  v-if="
                    textData.keyword &&
                    textData.keywordArry &&
                    textData.keywordArry.length > 10
                  "
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <div slot="content" v-html="textData.keyword"></div>
                  <span class="gengduo-link">(更多)</span>
                </el-tooltip>
                <span class="my-caozuo" @click="myCopy(textData.keyword, 6)">
                  <img src="../../../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="event-box">
                <span class="eventing">事件分类:</span>
                <!-- <div class="eventClassData"> -->
                <span>
                  {{ textData.eventList ? textData.eventList[0] : "无" }}
                </span>
                <!-- </div> -->
                <el-tooltip
                  v-if="textData.eventClass && textData.eventList.length >= 2"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <div slot="content" v-html="textData.eventClass"></div>
                  <span class="gengduo-link">(更多)</span>
                </el-tooltip>
                <span class="my-caozuo" @click="myCopy(textData.eventClass, 2)">
                  <img src="../../../assets/img/views/hfz.png" alt />
                </span>
              </div>
            </div>
            <div class="main-center-right">
              <div
                class="center-right-img"
                v-loading="loadingImg"
                element-loading-text="正在截图中"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="textData.fastPic"
                  :preview-src-list="srcList"
                >
                  <div slot="error" class="image-slot">
                    <img src="../../../assets/img/views/no.jpg" alt />
                  </div>
                </el-image>
              </div>
            </div>
          </div>
          <p
            v-html="item ? item : ''"
            class="main-text"
            v-for="(item, index) in textData.contentList"
            :key="index"
          ></p>
          <div
            v-if="textData.videoPath"
            class="demo-image__preview"
            style="margin-top: 16px"
          >
            <el-image
              style="max-width: 600px; max-height: 400px"
              v-if="textData.videoPath.type.slice(0, 5) == 'image'"
              :src="textData.videoPath.url"
              :preview-src-list="srcLists"
            ></el-image>
            <div v-if="textData.videoPath.type.slice(0, 5) == 'video'">
              <video
                width="100%"
                height="300px"
                :src="textData.videoPath.url"
                controls="controls"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="information-bottom">
      <div class="information-bottom-box" v-loading="likeLoadFlag">
        <div class="bottom-xiangsi">
          <div style="margin-right: 5px">
            <span class="bottom-xiangsi-one">相似</span>
            <div class="bottom-line"></div>
          </div>
          <span style="font-weight: bold; color: #333333"
            >({{ linkData }}条)</span
          >
          <div
            v-if="gengduodata.length != 0"
            @click="changeData"
            class="change-title"
          >
            <i class="el-icon-refresh"></i>换一换
          </div>
        </div>
        <!-- 推荐 -->
        <div class="bottom-count">
          <div
            @click="pushClick(item)"
            class="bottom-count-left"
            v-for="(item, index) in gengduodata"
            :key="index"
          >
            <div class="count-left-coution">
              <div
                :class="[
                  'type',
                  'type1',
                  item.num == 1
                    ? 'negative'
                    : item.num == 2
                    ? 'neutral'
                    : 'obverse',
                ]"
              >
                {{ item.num == 1 ? "负面" : item.num == 2 ? "中性" : "正面" }}
              </div>
              <!-- 网站 -->
              <div class="type left-website">
                {{ item.website == "网站" ? "网站" : "电视" }}
              </div>
              <!-- 是否预警 -->
              <div v-show="item.yujing" class="left-yujing">已预警</div>
              <div class="left-text">{{ item.title ? item.title : "无" }}</div>
            </div>

            <div class="bottom-text-box">
              <div class="bottom-text-caozuo">
                <span class="bottom-meiti">
                  媒体性质:{{
                    (item.mediaLink ? item.mediaLink : "其它") +
                    "/" +
                    (item.mediaAttribute ? item.mediaAttribute : "其它") +
                    "/" +
                    (item.mediaLevel ? item.mediaLevel : "其它")
                  }}
                </span>
                <span class="publics source">
                  来源/作者:
                  {{
                    (item.source ? item.source : "无") +
                    "/" +
                    (item.author ? item.author : "无")
                  }}
                </span>
                <span class="publics bottom-gengduo">
                  事件分类：
                  <span>
                    {{ item.eventList[0] ? item.eventList[0] : "无" }}
                  </span>
                  <el-tooltip
                    v-if="item.eventClass && item.eventList.length >= 2"
                    class="item"
                    effect="dark"
                    placement="top"
                  >
                    <div slot="content" v-html="item.eventClass"></div>
                    <span class="gengduo-link">(更多)</span>
                  </el-tooltip>
                </span>
                <span class="publics bottom-key">
                  关键字:
                  <span v-if="item.keyword">
                    <span
                      v-for="(ite, indx) in item.keywordList"
                      :class="[
                        ite != item.keywordList[item.keywordList.length - 1]
                          ? 'styleMargin'
                          : '',
                      ]"
                      :key="indx"
                      >{{ ite }}</span
                    >
                  </span>
                  <span v-else>无</span>
                  <el-tooltip
                    v-if="item.keyword && item.keywordArry.length > 3"
                    class="item"
                    effect="dark"
                    placement="top"
                  >
                    <div slot="content" v-html="item.keyword"></div>
                    <span class="gengduo-link">(更多)</span>
                  </el-tooltip>
                </span>
              </div>
              <span
                v-if="item.releaseTime != null"
                class="publics bottom-time"
                >{{ item.time ? item.time : "无" }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataReport } from "../../../api/message/list";

import {
  accurateInfo,
  setLabel,
  modifyLabel,
  screenShot,
  screenShotState,
  sentimentEdit,
  likeList,
  deleteLabel,
  commitNum,
} from "../../../api/accurate/list";
export default {
  data() {
    return {
      srcLists: [],
      loadingImg: false,
      srcList: [],
      img: "",
      // 精准舆情主键id
      id: "",
      // 相似列表
      dataId: "",
      // 设置标签
      biaoqianList: [],
      textData: {},
      count: 0,
      gengduodata: [],
      // 相似数量
      linkData: 0,
      loading: true,
      pageNum: 1,
      likeLoadFlag: false,
    };
  },
  components: {},
  methods: {
    pushClick2() {
      this.$router.push("/index");
    },
    // 相似列表详情
    pushClick(item) {
      let routeUrl = this.$router.resolve({
        path: "/index/accurateInfo",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.accurateId,
              dataId: item.dataId,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    handel() {
      let routeUrl = this.$router.resolve({
        path: "/index/accurateInfo/comment",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              count: this.count,
              dataId: this.dataId,
              accurateId: this.id,
              releaseTime: this.textData.releaseTime,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 查看原文
    routerOriginal(web) {
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(this.textData),
        operationRecode: 7,
        requestBody: web,
      });
      window.open(web);
    },
    // 评论数量
    async commitNum() {
      const res = await commitNum(this.dataId);
      this.count = res.data.data;
    },
    // 获取相似列表
    async likeList() {
      this.likeLoadFlag = true;
      const res = await likeList({
        accurateId: this.id,
        organId: this.$store.state.user.organizationId,
        pageNum: this.pageNum,
        pageSize: 5,
      });
      if (res != undefined) {
        if (res.data.data) {
          if (res.data.data.rows.length == 0 && this.pageNum == 1) {
            this.likeLoadFlag = false;
            return false;
          }
          if (res.data.data.rows.length == 0) {
            this.pageNum = 0;
            this.changeData();
          } else {
            this.gengduodata = res.data.data.rows;
            this.linkData = res.data.data.total;
            this.gengduodata.forEach((item) => {
              // item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
              if (item.eventClass) {
                item.eventList = item.eventClass.split(" ");
                let reg = new RegExp(" ", "g"); //g代表全部
                item.eventClass = item.eventClass.replace(reg, "<br/>");
              } else {
                item.eventList = [];
              }
              if (item.keyword) {
                item.keyword = item.keyword.replace(/<br\/>/g, " ");
                item.keywordList = item.keyword.split(" ");
                item.keywordArry = item.keyword.split(" ");
                if (item.keywordList.length > 3) {
                  item.keywordList = item.keywordList.slice(0, 3);
                }
                let reg = new RegExp(" ", "g"); //g代表全部
                item.keyword = item.keyword.replace(reg, "<br/>");
              } else {
                item.keywordList = [];
                item.keywordArry = [];
              }
            });
            this.likeLoadFlag = false;
          }
        }
      }
    },
    changeData() {
      this.pageNum++;
      this.likeList();
    },
    // 获取标签
    async setLabel() {
      const res = await setLabel({
        organizationId: this.$store.state.user.organizationId,
        noShow: 1,
      });
      if (res.data.rows) {
        this.biaoqianList = res.data.rows;
      }
    },
    // 数据上报
    async dataReport(item) {
      let data = {
        infoType: 1,
        inforMsgId: item.accurateId,
        organizationId: item.organId,
      };
      const res = await dataReport(data);
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(this.textData),
        operationRecode: 8,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          infoType: 1,
          inforMsgId: item.accurateId,
          organizationId: item.organId,
        }),
      });
      if (res != undefined) {
        if (res.data.data.code !== 200) {
          this.$message({
            message: res.data.data.msg,
            type: "warning",
          });
        } else {
          this.$message({
            message: res.data.data.msg,
            type: "success",
          });
        }
      }
    },
    // 修改标签
    async changeLabel(val) {
      if (val.setLabel == -1) {
        const res = await deleteLabel({
          accurateId: val.accurateId,
        });
        this.$log({
          operationModule: "1001103",
          operationDetails: JSON.stringify(this.textData),
          operationRecode: 9,
          requestUrl: res.config.baseURL + res.config.url,
          requestMethod: res.config.method,
          requestBody: JSON.stringify({
            accurateId: val.accurateId,
          }),
        });
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功,已取消标签！",
            type: "success",
          });
        } else {
          this.$message.error("操作失败，请稍后再试!");
        }
        this.getList();
        return;
      }
      let res = await modifyLabel({
        dataId: val.dataId,
        setLabel: val.setLabel,
        organId: this.$store.state.user.organizationId,
      });
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(this.textData),
        operationRecode: 9,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          dataId: val.dataId,
          setLabel: val.setLabel,
          organId: this.$store.state.user.organizationId,
        }),
      });
      if (res.data.code == 200) {
        this.$message({
          message: "修改标签成功！",
          type: "success",
        });
      } else {
        this.$message.error("修改标签失败！");
      }
      this.getList();
    },
    // 界面截图
    async screenShot(item) {
      this.textData.disabled = true;
      this.loadingImg = true;
      const res = await screenShot({
        typeStr: "PUBLIC_SENTIMENT",
        infoId: item.accurateId,
        orgaId: this.$store.state.user.organizationId,
        uuid: item.screenshotAddress,
        website: item.website,
      });
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(this.textData),
        operationRecode: 3,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          typeStr: "PUBLIC_SENTIMENT",
          infoId: item.accurateId,
          orgaId: this.$store.state.user.organizationId,
          uuid: item.screenshotAddress,
          website: item.website,
        }),
      });
      if (res.data.code == 200) {
        this.$message({
          message: res.data.data.ResponseMsg,
          type: "success",
        });
        this.screenShotState();
      }
    },
    // 查看是否截图状态
    async screenShotState() {
      const res = await screenShotState({
        infoId: this.textData.accurateId,
        orgaId: this.$store.state.user.organizationId,
        typeStr: "PUBLIC_SENTIMENT",
        uuid: this.textData.screenshotAddress,
      });
      if (res.data.code == 200 && res.data.data.type == -1) {
        return false;
      }
      if (res.data.code == 200 && res.data.data.type == 0) {
        this.textData.disabled = true;
        this.loadingImg = true;
        setTimeout(() => {
          this.screenShotState();
        }, 60000);
      } else if (res.data.code == 200 && res.data.data.type == 1) {
        this.$message.success("截图成功！");
        this.loadingImg = false;
        this.textData.disabled = false;
        setTimeout(() => {
          this.getList(1);
        }, 1000);
      }
    },
    // 收藏
    async editShouc(item) {
      let res = await sentimentEdit({
        accurateId: item.accurateId,
        organId: item.organId,
        isCollect: item.isCollect == "0" ? "1" : "0",
      });
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(this.textData),
        operationRecode: 4,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          accurateId: item.accurateId,
          organId: item.organId,
          isCollect: item.isCollect == "0" ? "1" : "0",
        }),
      });
      if (res != undefined) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        item.isCollect = item.isCollect == "0" ? "1" : "0";
      }
    },

    copy(msg) {
      this.$publicFun.copyFun(msg.copy);
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(msg),
        operationRecode: 1,
        requestBody: msg.copy,
      });
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.title + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(msg),
        operationRecode: 2,
        requestBody: str,
      });
    },
    myCopy(msg, type) {
      let str = "";
      if (type == 4) {
        msg = msg.map((item) => {
          return item ? item : "其它";
        });
        str = `媒体性质:${msg}`;
      }
      msg = msg ? msg : "无";
      if (type == 1) {
        let author =
          (this.textData.source ? this.textData.source : "无") +
          "/" +
          (this.textData.author ? this.textData.author : "无");
        str = `来源/作者:${author}`;
      } else if (type == 2) {
        str = `事件分类:${msg}`;
      } else if (type == 6) {
        str = `关键字:${msg}`;
      }else if (type == 7) {
        str = `作者ID:${msg}`;
      }
      this.$publicFun.copyFun(str);
      this.$log({
        operationModule: "1001103",
        operationDetails: JSON.stringify(msg),
        operationRecode: 19,
        requestBody: str,
      });
    },
    // 获取列表
    async getList(type) {
      this.loading = true;
      const res = await accurateInfo({
        dataId: this.id,
        organId: this.$store.state.user.organizationId,
      });
      if (res.data.data != undefined) {
        this.textData = res.data.data;
        this.textData.accurateId = this.textData.accurateId + "";
        this.textData.dataId = this.textData.dataId + "";
        if (this.textData.videoPath) {
          if (this.textData.videoPath.indexOf('"type":') == -1) {
            this.textData.videoPath = {
              url: this.textData.videoPath,
              type: "video/mp4",
            };
          } else {
            this.textData.videoPath = JSON.parse(this.textData.videoPath);
            if (this.textData.videoPath.type.slice(0, 5) == "image") {
              this.srcLists[0] = this.textData.videoPath.url;
            }
          }
        }
        if (this.textData.eventClass) {
          this.textData.eventList = this.textData.eventClass.split(" ");
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.eventClass = this.textData.eventClass.replace(
            reg,
            "<br/>"
          );
        } else {
          this.textData.eventList = [];
        }
        if (this.textData.keyword) {
          this.textData.keyword = this.textData.keyword.replace(/<br\/>/g, " ");
          this.textData.keywordList = this.textData.keyword.split(" ");
          this.textData.keywordArry = this.textData.keyword.split(" ");
          if (this.textData.keywordList.length > 10) {
            this.textData.keywordList = this.textData.keywordList.slice(0, 10);
          }
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
        }
        if (this.textData.content) {
          this.textData.contentList = this.textData.content.split("\n");
        }
        if (this.textData.fastPic) {
          this.srcList.push(this.textData.fastPic);
        } else if (!type && !this.textData.fastPic) {
          this.screenShotState();
        }
        let arrList = [];
        this.biaoqianList.forEach((item) => {
          arrList.push(item.labelId);
        });
        if (arrList.indexOf(this.textData.setLabel) == -1) {
          this.textData.setLabel = null;
        }
      }
      this.heightText();
      this.loading = false;
      return res;
    },
    heightText() {
      if (this.textData.keyword) {
        let word = this.textData.keyword.split("<br/>");
        if (word[word.length - 1] == "") {
          word.pop();
        }
        if (word.length != 0) {
          word.forEach((ite) => {
            let reg = new RegExp(ite, "g"); //g代表全部
            if (this.textData.title) {
              this.textData.title = this.textData.title.replace(
                reg,
                '<span style="color:red">' + ite + "</span>"
              );
            }
            if (this.textData.contentList) {
              for (let i = 0; i < this.textData.contentList.length; i++) {
                this.textData.contentList[i] = this.textData.contentList[
                  i
                ].replace(reg, '<span style="color:red;">' + ite + "</span>");
                // font-weight:800;font-size:18px
              }
            }
          });
        }
      }
    },
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.dataId;
      this.id = params.id;
    }
    this.getList().then((res) => {
      this.$log({
        operationModule: "1001103",
        operationDetails: res.data.data ? JSON.stringify(res.data.data) : "",
        operationRecode: 10,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          dataId: this.id,
          organId: this.$store.state.user.organizationId,
        }),
      });
    });
    this.setLabel();
    this.commitNum();
  },
};
</script>

<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.main-top-center-box {
  display: flex;
  align-items: center;
}

.main-top-center-box div {
  margin-left: 0;
  width: 58px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  padding: 0 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
}

.main-top-center-box p {
  width: 94%;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.comment-weidu {
  color: #ea3342;
}

.information-box {
  padding: 0 24px 16px 24px;
}

.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../../../assets/img/views/beijing.png");
  background-size: 37px 55px;
}

.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../../../assets/img/views/beijing.png");
}

.center-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.type {
  padding: 0 5px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  height: 24px;
}

.left-website {
  margin-left: 8px;
  background-color: #868bff;
}

.website {
  margin-left: 16px;
  background-color: #868bff;
}

.original {
  display: flex;
  margin-left: 16px;
  cursor: pointer;
  font-size: 14px;
}

.original-color {
  color: #2e59ec;
}

.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}

.center-timer {
  display: flex;
  justify-content: space-between;
  width: 213px;
  margin-left: 24px;
  font-size: 14px;
  color: #999999;
}

.center-bottom {
  height: 35px;
  margin-top: 16px;
}

.gengduo {
  padding-left: 8px;
  width: 46px;
  height: 20px;
  cursor: pointer;
  color: #2e59ec;
  font-size: 14px;
}

.center-key {
  font-size: 14px;
  color: #999999;
}

.main-text {
  margin-top: 24px;
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
}

.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}

.operation-box img {
  width: 16px;
  height: 16px;
}

.operation-box {
  position: relative;
  margin-top: 18px;
  display: flex;
  width: 1137px;
  font-size: 14px;
  color: #333333;
  align-items: center;
}

.operation-box > div:not(:first-child) {
  margin-left: 40px;
}

.operation-box > div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.operation-box > div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}

.pinglun-num {
  position: absolute;
  right: 0;
  cursor: pointer;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
}

.main-center {
  padding: 24px 24px 24px 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background: #f4f8fd;
}

.main-center > div {
  height: auto;
  font-size: 14px;
  color: #333333;
}

.main-center-left {
  display: flex;
  width: 89%;
  flex-wrap: wrap;
  align-items: center;
}

.event-box {
  width: 71%;
  height: 20px;
  display: flex;
  align-items: center;
}

.event-box .my-caozuo {
  margin-left: 5px;
  margin-top: 5px;
}

.left-source,
.left-language {
  display: flex;
  align-items: center;
  width: 50%;
}

.left-source .my-caozuo,
.left-language .my-caozuo {
  margin-top: 5px;
  margin-left: 5px;
}

.center-right-img {
  width: 111px;
  height: 108px;
}

.center-right-img img {
  width: 111px;
  height: 108px;
}

.main-center-right {
  border: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}

.information-bottom {
  padding: 0px 24px 24px 24px;
}

.information-bottom-box {
  padding: 40px 240px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
}

.bottom-xiangsi-one {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.bottom-xiangsi {
  align-items: center;
  display: flex;
}

.bottom-line {
  width: 42px;
  height: 6px;
  background: linear-gradient(90deg, #4ed2ff 0%, #0d8bff 98%);
}

.bottom-count {
  display: flex;
  flex-wrap: wrap;
}

.bottom-count-left {
  width: 100%;
  margin-top: 32px;
}

.left-yujing {
  padding: 0 8px;
  margin-left: 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
  line-height: 24px;
}

.left-text {
  margin-left: 16px;
  width: 576px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}

.count-left-coution {
  display: flex;
}

.left-time {
  font-size: 14px;
  color: #333333;
}

.yidu {
  color: #02bc7c;
}

.bottom-text-box {
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
}

.bottom-text-caozuo > span:not(:first-child) {
  margin-left: 40px;
}

.bottom-text-caozuo {
  margin-top: 16px;
}

.bottom-meiti {
  font-size: 14px;
  color: #b8bcc3;
}

.publics {
  font-size: 14px;
  color: #b8bcc3;
  margin-top: 16px;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.change-title {
  color: #626675;
  float: right;
  margin-left: 20px;
  cursor: pointer;
}
</style>
